import { useEffect, memo } from 'react';
import {
  useSetToken,
  OAuthAccessTokenApi,
  STORAGE_CALLBACK_URL_KEY,
  CasdoorSDK,
} from '@daimaxiagu/micro-frontend-provider';
import { rawAuth, hashify, save } from './storage';

export default memo(() => {
  const updateToken = useSetToken();
  useEffect(() => {
    (async () => {
      try {
        const code = CasdoorSDK.getCallbackCodeAndState();
        const { accessToken, refreshToken } = await OAuthAccessTokenApi(code);
        updateToken(accessToken, refreshToken);
        rawAuth.accessToken = accessToken;
        rawAuth.hashedAccessToken = hashify(accessToken);
        if (refreshToken) {
          rawAuth.refreshToken = refreshToken;
          rawAuth.hashedRefreshToken = hashify(refreshToken);
        } else if (refreshToken === null) {
          rawAuth.refreshToken = undefined;
          rawAuth.hashedRefreshToken = undefined;
        }
        save();
        const href = sessionStorage.getItem(STORAGE_CALLBACK_URL_KEY) ?? '/';
        sessionStorage.removeItem(STORAGE_CALLBACK_URL_KEY);
        location.href = href;
      } catch (e) {
        //
      }
    })();
  }, []);
  return <></>;
});
