import { CSSProperties } from 'react';
import { OnChange as MonacoOnChange } from '@monaco-editor/react';
import { CodeMirrorOnChange } from './CodeMirrorEditor/CodeMirrorEditor';
import { ScratchOnChange } from './ScratchEditor/ScratchEditor';

export interface IMixCodeEditorInstance {
  type: MixCodeEditorType;
  getValue: () => string;
  setValue: (value: string, cursor?: number) => void;
  getCode: () => string;
  insert?: (value: string) => void;
  getCursor?: () => number;
}

export interface IMixCodeEditorProps {
  editor?: MixCodeEditorType | 'auto';
  onMount?: (instance: IMixCodeEditorInstance) => void | (() => void);
  onChange?: MonacoOnChange | CodeMirrorOnChange | ScratchOnChange;
  language?: string;
  code?: string;
  style?: CSSProperties;
  transparent?: boolean;
  readOnly?: boolean;
  throttleTime?: number;
}

export enum MixCodeEditorType {
  MonacoEditor = 'monaco',
  CodeMirrorNext = 'codemirror',
  Scratch = 'scratch',
}

export enum LanguageType {
  CPP = 'cpp',
  Python = 'python',
  Java = 'java',
  JSON = 'json',
  YAML = 'yaml',
  TOML = 'toml',
  XML = 'xml',
  CSS = 'css',
  HTML = 'html',
  JavaScript = 'javascript',
  TypeScript = 'typescript',
  Markdown = 'markdown',
  Rust = 'rust',
  Solidity = 'solidity',
  Golang = 'golang',
  Move = 'move',
  Ruby = 'ruby',
  Lua = 'lua',
  Assembly = 'assembly',
  Kotlin = 'kotlin',
  CSharp = 'csharp',
  VisualBasic = 'visual-basic',
  Pascal = 'pascal',
  Swift = 'swift',
  ObjectiveC = 'objective-c',
}

export const translateLanguageType = (
  language: string,
): LanguageType | 'unknown' =>
  (({
    c: 'cpp',
    cpp: 'cpp',
    'c++': 'cpp',
    python: 'python',
    python2: 'python',
    python3: 'python',
    java: 'java',
    json: 'json',
    yml: 'yaml',
    yaml: 'yaml',
    toml: 'toml',
    xml: 'xml',
    css: 'css',
    html: 'html',
    htm: 'html',
    js: 'javascript',
    javascript: 'javascript',
    ts: 'typescript',
    typescript: 'typescript',
    md: 'markdown',
    markdown: 'markdown',
    rust: 'rust',
    sol: 'solidity',
    solidity: 'solidity',
    go: 'golang',
    golang: 'golang',
    move: 'move',
    ruby: 'ruby',
    lua: 'lua',
    asm: 'assembly',
    asmx86: 'assembly',
    kt: 'kotlin',
    kotlin: 'kotlin',
    cs: 'csharp',
    csharp: 'csharp',
    'c#': 'csharp',
    vb: 'visualbasic',
    visualbasic: 'visual-basic',
    pascal: 'pascal',
    swift: 'swift',
    oc: 'objective-c',
    'objective-c': 'objective-c',
  }[language.toLowerCase()] ?? 'unknown') as any);
