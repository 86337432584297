import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingMask from '.';

export interface IRetriableLoadingMask {
  retry: () => void;
  loading: boolean;
  error: Error | undefined;
  style?: SxProps;
}

export default ({
  retry,
  loading,
  error,
  style = {},
  children = <></>,
}: IRetriableLoadingMask & { children?: JSX.Element }) => {
  const maskContent = React.useMemo(() => {
    if (loading) {
      return <h1>加载中...</h1>;
    } else if (error === undefined) {
      return <></>;
    } else {
      return (
        <>
          <h1>出错啦</h1>
          <Box
            component="p"
            sx={{ color: 'error.main' }}
          >{`Message: ${error.toString()}`}</Box>
          <br />
          <Button
            startIcon={<RefreshIcon />}
            onClick={() => retry()}
            size="large"
          >
            点击刷新
          </Button>
        </>
      );
    }
  }, [error, loading]);

  return (
    <LoadingMask
      show={loading || error !== undefined}
      loadingIcon={loading}
      content={maskContent}
      style={{
        width: '100%',
        height: '100%',
        ...style,
      }}
    >
      {children}
    </LoadingMask>
  );
};
