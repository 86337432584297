import { ILevelCardInfo, CodeLanguageType } from '@/types/data.d';

export interface ILevelCardCodePersistance {
  set: (props: {
    code: string;
    cursor?: number;
    cardId?: string;
    language: CodeLanguageType;
  }) => void;
  setCursor: (props: {
    cursor: number;
    cardId?: string;
    language: CodeLanguageType;
  }) => void;
  get: (props: {
    cardId?: string;
    language: CodeLanguageType;
  }) => Promise<[string, number] | undefined>;
  setLanguage: (props: {
    language: CodeLanguageType;
    cardData: ILevelCardInfo;
    cardId?: string;
  }) => void;
  getLanguage: (props: {
    cardData: ILevelCardInfo;
    cardId?: string;
  }) => Promise<CodeLanguageType>;
}

export class MemoryCodePersistance implements ILevelCardCodePersistance {
  private map: Map<string, [string, number]> = new Map();

  private language?: CodeLanguageType = undefined;

  set({
    code,
    cursor = 0,
    cardId = '',
    language,
  }: {
    code: string;
    cursor?: number;
    cardId?: string;
    language: CodeLanguageType;
  }) {
    if (language && cardId) {
      this.map.set(`${cardId}.${language}`, [code, cursor]);
    }
  }

  setCursor({
    cursor,
    cardId = '',
    language,
  }: {
    cursor: number;
    cardId?: string;
    language: CodeLanguageType;
  }) {
    if (cardId && language) {
      const state = this.map.get(`${cardId}.${language}`);
      if (state !== undefined) {
        state[1] = cursor;
      }
    }
  }

  async get({
    cardId = '',
    language,
  }: {
    cardId?: string;
    language: CodeLanguageType;
  }) {
    return this.map.get(`${cardId}.${language}`);
  }

  setLanguage({
    language,
  }: {
    language: CodeLanguageType;
    cardData: ILevelCardInfo;
    cardId?: string;
  }) {
    if (language) {
      this.language = language;
    }
  }

  async getLanguage({
    cardData,
    cardId,
  }: {
    cardData: ILevelCardInfo;
    cardId?: string;
  }) {
    if (
      this.language === undefined ||
      !cardData.supportedLanguages.includes(this.language)
    ) {
      this.setLanguage({
        language: cardData.defaultLanguage || cardData.supportedLanguages[0],
        cardData,
        cardId,
      });
    }
    return this.language!;
  }
}
