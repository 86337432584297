import React from 'react';
import { Helmet } from '@modern-js/runtime/head';
import { UserGateKeeper } from '@daimaxiagu/micro-frontend-provider';
import LevelView from '@/components/card/gamelevel/LevelView';

const LevelIndex = ({ match: { params } }: any) => {
  const [title, setTitle] = React.useState<string>('加载中...');
  return (
    <UserGateKeeper
      object="daimaxiagu:single-card-page.gamelevel"
      action="visit"
      goBack
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LevelView levelId={params?.levelId.trim() ?? ''} setTitle={setTitle} />
    </UserGateKeeper>
  );
};

export default LevelIndex;
