import { IUtilsContext } from '@daimaxiagu/micro-frontend-provider';
import { get, set, getOrSetDefault, batchFetch } from './storage';

export const play = (src = '') => {
  const audioDom = document.querySelector<HTMLAudioElement>(
    'audio#daimaxiagu-global-audio',
  );
  if (audioDom === null) {
    return;
  }
  try {
    audioDom.pause();
    audioDom.src = src;
    audioDom.play();
  } catch (e) {}
};

export const utilsContextValue: IUtilsContext = {
  storage: {
    get,
    set,
    getOrSetDefault,
    batchFetch,
  },
  audio: {
    play,
  },
};
