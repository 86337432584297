import React from 'react';

export type CallRevealJSMethodFunction<ReturnValue = undefined> = (
  ...args: any[]
) => Promise<ReturnValue>;

export interface RevealJSMethods {
  [methodName: string]: CallRevealJSMethodFunction;
}

interface IEmbbedRevealJSProps {
  uri: string;
  style?: React.CSSProperties;
  onMount?: (methods: RevealJSMethods) => void | (() => void);
  onMessage?: (eventName: string, event: Record<string, unknown>) => void;
}

let nextRevealJSFrameId = 0;
const EmbbedRevealJS: React.NamedExoticComponent<IEmbbedRevealJSProps> =
  React.memo<IEmbbedRevealJSProps>(
    ({ uri, onMount, onMessage, style = {} }) => {
      const id = React.useMemo(() => nextRevealJSFrameId++, []);
      const callbackResolveMap = React.useMemo<
        Map<string, [(result: any) => void, (error: Error) => void]>
      >(() => new Map(), []);

      React.useEffect(() => {
        callbackResolveMap.clear();
      }, [id, uri]);

      const callMethod = React.useCallback(
        (method, args) =>
          new Promise((resolve, reject) => {
            const target = (
              document.querySelector(
                `iframe#embedded-revealjs-container-${id}`,
              ) as HTMLIFrameElement
            )?.contentWindow;
            if (target) {
              callbackResolveMap.set(method, [resolve, reject]);
              target.postMessage(
                JSON.stringify({ method, args: [...(args ?? [])] }),
                '*',
              );
            } else {
              reject(new Error('Embedded RevealJS is not ready!'));
            }
          }),
        [id],
      );
      const methods = React.useMemo<RevealJSMethods>(
        () =>
          new Proxy(
            {},
            {
              get: (_target, property) =>
                typeof property === 'string'
                  ? async (...args: any[]) => await callMethod(property, args)
                  : async () => undefined,
            },
          ),
        [callMethod],
      );

      React.useEffect(() => {
        const messageListener = (event: MessageEvent) => {
          if (typeof event.data !== 'string') {
            return;
          }
          let method: string | undefined;
          try {
            const data = JSON.parse(event.data);
            if (
              data.namespace === 'reveal' &&
              data.eventName === 'callback' &&
              data.method !== '' &&
              callbackResolveMap.has(data.method)
            ) {
              // eslint-disable-next-line prefer-destructuring
              method = data.method;
              callbackResolveMap.get(data.method)![0](data.result);
              callbackResolveMap.delete(data.method);
            }
          } catch (e: any) {
            if (method) {
              callbackResolveMap.get(method)?.[1]?.(e);
              callbackResolveMap.delete(method);
            }
          }
        };
        window?.addEventListener?.('message', messageListener);
        return () => {
          window?.removeEventListener?.('message', messageListener);
        };
      }, []);

      React.useEffect(() => {
        if (onMessage) {
          const messageListener = (event: MessageEvent) => {
            if (typeof event.data !== 'string') {
              return;
            }
            try {
              const data = JSON.parse(event.data);
              if (data.namespace === 'reveal') {
                onMessage(data.eventName, data);
              }
            } catch (e) {}
          };
          window?.addEventListener?.('message', messageListener);
          return () => {
            window?.removeEventListener?.('message', messageListener);
          };
        } else {
          return () => {
            //
          };
        }
      }, [id, onMessage]);

      React.useEffect(() => {
        if (onMount) {
          return onMount(methods);
        } else {
          return () => {
            //
          };
        }
      }, [methods, onMount]);

      return (
        <iframe
          style={{ width: '100%', height: '100%', borderWidth: '0', ...style }}
          id={`embedded-revealjs-container-${id}`}
          src={uri}
        />
      );
    },
  );

export default EmbbedRevealJS;
