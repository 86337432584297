import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
} from 'react-redux';
import { createLogger } from 'redux-logger';
import { reducers } from '@daimaxiagu/redux-partner';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { DEV } from '@daimaxiagu/micro-frontend-provider';

const rootReducer = combineReducers({
  ...reducers,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: DEV,
  middleware: getDefaultMiddleware =>
    // 无需再添加thunk，默认添加
    getDefaultMiddleware({
      // 不可修改性检查
      immutableCheck: DEV,
      // 可序列化检查
      serializableCheck: false,
    }).concat(
      DEV
        ? [
            createLogger({
              level: 'debug',
              duration: true,
              diff: true,
            }),
          ]
        : [],
    ),
});

export const useDispatch = () => useReduxDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useReduxSelector;
