import { cloneDeep } from 'lodash';
import { validate, Schema } from 'jsonschema';
import { IQuestionInfo, SubProblemType } from '@/types/data.d';

const QuestionJsonSchema: Schema = {
  type: 'object',
  properties: {
    title: {
      type: ['string', 'null'],
    },
    type: {
      type: 'string',
      enum: ['1'],
      required: true,
    },
    content: {
      type: ['string', 'null'],
    },
    explanation: {
      type: ['string', 'null'],
    },
    subproblems: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          content: {
            type: 'string',
          },
          explanation: {
            type: 'string',
          },
          explanationVideo: {
            type: ['string', 'null'],
          },
          random: {
            type: ['boolean'],
          },
          type: {
            type: 'string',
            enum: ['single'],
          },
          options: {
            type: 'array',
            required: true,
            items: {
              type: 'array',
              items: {
                type: ['string', 'boolean'],
              },
              maxItems: 2,
              minItems: 2,
            },
            minItems: 2,
          },
        },
        required: ['content', 'explanation', 'options', 'type'],
      },
      required: true,
    },
  },
  required: true,
};

export interface IQuestionSubProblemJson {
  content: string;
  options: [string, boolean][];
  explanation: string;
  explanationVideo?: string | null;
  type: 'single';
  random?: boolean | null;
}

export interface IQuestionInfoJson {
  title?: string | null;
  type: string;
  content?: string | null;
  subproblems: IQuestionSubProblemJson[];
  explanation?: string | null;
}

export const parseQuestion = (questionJsonLike: unknown): IQuestionInfo => {
  validate(questionJsonLike, QuestionJsonSchema, { throwFirst: true });
  const questionJson = questionJsonLike as IQuestionInfoJson;
  return {
    title: questionJson.title ?? undefined,
    content: questionJson.content ?? undefined,
    explanation: questionJson.explanation ?? undefined,
    subQuestions: questionJson.subproblems
      .filter(problem => ['single'].includes(problem.type))
      .map(problem => {
        const optionMap = problem.options.map((_option, index) => index);
        const optionMapRevert = cloneDeep(optionMap);
        if (problem.random !== false) {
          // Thanks to https://stackoverflow.com/a/2450976/9610231
          let currentIndex = optionMap.length;
          while (currentIndex !== 0) {
            const randomIndex = Math.floor(Math.random() * currentIndex--);
            [optionMap[currentIndex], optionMap[randomIndex]] = [
              optionMap[randomIndex],
              optionMap[currentIndex],
            ];
            optionMapRevert[optionMap[currentIndex]] = currentIndex;
          }
        }
        return {
          content: problem.content,
          options: problem.options,
          explanation: problem.explanation,
          explanationVideo: problem.explanationVideo ?? undefined,
          type: SubProblemType.Single,
          optionMap,
          optionMapRevert,
        };
      }),
  };
};
