import React from 'react';
import { usePartner } from '@daimaxiagu/redux-partner';
import { useAudio } from '@daimaxiagu/micro-frontend-provider';

import { SlideCardPartner } from './partner';

import { useSlide } from '@/utils/api/gameCard';
import RetriableLoadingMask from '@/components/LoadingMask/RetriableLoadingMask';
import EmbbedRevealJS from '@/components/card/slide/EmbbededRevealJS';

export interface ISlideViewProps {
  slideId: string;
  setTitle?: (title: string) => void;
  style?: React.CSSProperties;
  onMount?: (partner: SlideCardPartner) => (() => void) | void;
}

const SlideView = React.memo<ISlideViewProps>(({ slideId, style, onMount }) => {
  const audio = useAudio();
  const partner = usePartner(() => new SlideCardPartner(audio));
  const [slideUrl, error, loading, , retry] = useSlide(slideId, [slideId]);

  React.useEffect(() => {
    if (partner && onMount) {
      return onMount(partner);
    }
    return () => {
      //
    };
  }, [partner, onMount]);

  React.useEffect(() => {
    partner.bindSlide(slideId);
  }, [slideId]);

  return (
    <RetriableLoadingMask
      error={error}
      loading={loading}
      retry={retry}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {slideUrl ? (
        <EmbbedRevealJS
          uri={slideUrl}
          style={style}
          onMessage={(name, data) => partner.slideEvents.emit(name, data)}
          onMount={methods => {
            partner.slideMethods = methods;
            partner.cardReady();
            partner.revealJsReady();
            return () => {
              delete partner.slideMethods;
            };
          }}
        />
      ) : (
        <></>
      )}
    </RetriableLoadingMask>
  );
});

export default SlideView;
