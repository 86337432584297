import { memo, useMemo } from 'react';
import { Provider } from 'react-redux';
import { Helmet } from '@modern-js/runtime/head';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Switch, Route, Redirect } from '@modern-js/runtime/router';
import { UtilsContext } from '@daimaxiagu/micro-frontend-provider';

import Test from '@/pages/test';
import Level from '@/pages/level';
import Paper from '@/pages/paper';
import Slide from '@/pages/slide';
import Question from '@/pages/question';
import Dashboard from '@/pages/dashboard';
import CocosDebugger from '@/pages/debug';
import Collection from '@/pages/collection';
import ManagementPage from '@/pages/manage';
import VideoSharePage from '@/pages/share/video';
import SubmitHistory from '@/pages/submitHistory';
import AuthProvider from '@/components/oauth/AuthProvider';
import { utilsContextValue } from '@/components/utils/index';
import OAuthLoginCallback from '@/components/oauth/OAuthLoginCallback';
import MicroFrontends from '@/components/MicroFrontends';
import { store } from '@/utils/redux';
import WXRoute from '@/pages/wx/route';
import BattleSimple1V1V0 from '@/pages/battle/simple1v1/v0';

import './App.css';

export default memo(() => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          background: {
            primary: '#282C34',
          },
        },
      } as any),
    [],
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>代码峡谷 - 学编程,我要在峡谷玩!</title>
      </Helmet>
      <AuthProvider>
        <UtilsContext.Provider value={utilsContextValue}>
          <Provider store={store}>
            <Switch>
              <Route exact path="/test" component={Test} />
              <Route path={['/wx/*', '/wx']} component={WXRoute} />
              <Route path="/collection/:id/:cardId?" component={Collection} />
              <Route path="/card/gamelevel/:levelId" component={Level} />
              <Route path="/card/paper/:paperId" component={Paper} />
              <Route path="/card/slide/:slideId" component={Slide} />
              <Route path="/card/question/:questionId?" component={Question} />
              <Route path="/debug/:cocosUri" component={CocosDebugger} />
              <Route path="/share/levelvideo/:id" component={VideoSharePage} />
              <Route exact path={['/dashboard', '/']} component={Dashboard} />
              <Route
                path={['/app/class-manager', '/app/class-manager/*']}
                component={MicroFrontends.ClassManager}
              />
              <Route
                path={['/app/permission-manager', '/app/permission-manager/*']}
                component={MicroFrontends.PermissionManager}
              />
              <Route
                path="/app/submit-history/:username?"
                component={SubmitHistory}
              />
              <Route path={['/ide', '/ide/*']} component={MicroFrontends.IDE} />
              <Route
                exact
                path={['/app/global-classroom', '/app/global-classroom/*']}
                component={ManagementPage}
              />
              <Route
                exact
                path="/oauth-callback"
                component={OAuthLoginCallback}
              />
              <Route
                path="/battle/simple1v1/v0/:roomCode?"
                component={BattleSimple1V1V0}
              />
              <Route exact path="/algomage">
                <Redirect to="/algomage/" />
              </Route>
              <Route path="*">
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </Provider>
        </UtilsContext.Provider>
      </AuthProvider>
    </ThemeProvider>
  );
});
