import React from 'react';
import { debounce } from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  DEV,
  login,
  IAuth,
  UserType,
  AuthContext,
  jwtApiContext,
  setDefaultAvatarUrl,
  GetAnonymousTokenApi,
  RefreshAccessTokenApi,
  SetAnonymousAvatarUrl,
} from '@daimaxiagu/micro-frontend-provider';

import {
  load,
  save,
  rawAuth,
  checkJWT,
  hashify,
  IJWTPayload,
  jwtPayloadAvaliableProperties,
} from './storage';
import { Authorizer } from './Authorizer';

const casbinHeader = {
  Authorization: [] as string[],
};

const authorizer = new Authorizer();

setDefaultAvatarUrl(
  `https://oss.daimaxiagu.com/${DEV ? 'casdoor-dev' : 'casdoor'}/default.jpg`,
);
SetAnonymousAvatarUrl(
  `https://oss.daimaxiagu.com/${DEV ? 'casdoor-dev' : 'casdoor'}/anonymous.jpg`,
);

export default ({ children }: any) => {
  const [auth, setAuth] = React.useState<IAuth | undefined>();
  const [showLoginDialog, setShowLoginDialog] = React.useState<boolean>(false);

  const tryRefresh = React.useMemo(
    () =>
      debounce(
        async (
          refreshToken: string | undefined = rawAuth.refreshToken,
        ): Promise<{ accessToken?: string; refreshToken?: string }> => {
          console.warn('[refresh token]', { refreshToken });
          try {
            if (refreshToken) {
              return await RefreshAccessTokenApi(refreshToken);
            }
          } catch (e) {
            console.warn('[refresh token] Failed', { e });
            return {};
          }
          console.warn('[refresh token] But no refresh_token');
          return {};
        },
        5000,
        {
          leading: true,
        },
      ),
    [],
  );

  const updateAuth = React.useMemo(() => {
    const updateAuth = async (
      accessToken: string | undefined = undefined,
      refreshToken: string | undefined | null = undefined,
      dialog = true,
      verifyAccessToken = true,
    ) => {
      let payload: IJWTPayload | undefined;
      if (verifyAccessToken) {
        if (accessToken !== undefined) {
          const result = await checkJWT(accessToken, true);
          if (!result && rawAuth.refreshToken) {
            const n = await tryRefresh();
            if (n.accessToken && n.refreshToken) {
              updateAuth(n.accessToken, n.refreshToken, dialog, false);
              return;
            } else {
              rawAuth.refreshToken = undefined;
              updateAuth();
              return;
            }
          } else {
            payload = result;
          }
        }
      } else {
        payload = await checkJWT(accessToken!, false);
      }
      tryRefresh.cancel();
      if (payload === undefined) {
        casbinHeader.Authorization = [];
        rawAuth.tokenTime = Date.now();
        rawAuth.accessToken = undefined;
        rawAuth.hashedAccessToken = undefined;
        rawAuth.refreshToken = undefined;
        rawAuth.hashedRefreshToken = undefined;
        jwtApiContext.accessToken = '';
        if (dialog) {
          setShowLoginDialog(true);
        }
        await authorizer.setUser('anonymous:anonymous');
        setAuth({
          subject: `anonymous:${rawAuth.deviceId!}`,
          authorizer: authorizer as any,
          userName: '匿名用户',
          userType: UserType.Anonymous,
          isDefaultAvatar: false,
          emailVerified: false,
          address: [],
          properties: {},
          deviceID: rawAuth.deviceId!,
          login,
          setToken: (accessToken, refreshToken) =>
            updateAuth(accessToken, refreshToken, false),
          inMicroFrontend: false,
        });
      } else {
        casbinHeader.Authorization = [`Bearer ${accessToken}`];
        rawAuth.tokenTime = payload.iat * 1000;
        rawAuth.accessToken = accessToken;
        jwtApiContext.accessToken = accessToken ?? '';
        rawAuth.hashedAccessToken = hashify(accessToken!);
        if (refreshToken) {
          rawAuth.refreshToken = refreshToken;
          rawAuth.hashedRefreshToken = hashify(refreshToken);
        } else if (refreshToken === null) {
          rawAuth.refreshToken = undefined;
          rawAuth.hashedRefreshToken = undefined;
        }
        setShowLoginDialog(false);
        let subject = '';
        let casbinSubject = '';
        let userId: number | undefined;
        switch (payload.type) {
          case UserType.Anonymous: {
            subject = `anonymous:${rawAuth.deviceId!}`;
            casbinSubject = 'anonymous:anonymous';
            payload.isDefaultAvatar = false;
            break;
          }
          case UserType.Normal: {
            userId = Number(payload.sub);
            subject = `user:${payload.sub}`;
            casbinSubject = subject;
            break;
          }
          default: {
            subject = `bot:${rawAuth.deviceId!}`;
            casbinSubject = 'bot:bot';
          }
        }
        await authorizer.setUser(casbinSubject);
        const p: Record<string, string | undefined> = {};
        jwtPayloadAvaliableProperties.forEach(property => {
          p[property] =
            payload![property] === ''
              ? undefined
              : (payload![property] as string);
        });
        setAuth({
          subject,
          authorizer: authorizer as any,
          userId,
          login,
          userName: payload.name,
          userType: payload.type,
          userToken: accessToken,
          userAvatar: payload.avatar === '' ? undefined : payload.avatar,
          isDefaultAvatar: payload.isDefaultAvatar,
          emailVerified: payload.emailVerified,
          address: payload.address,
          properties: payload.properties,
          deviceID: rawAuth.deviceId!,
          setToken: (accessToken, refreshToken) =>
            updateAuth(accessToken, refreshToken, false),
          inMicroFrontend: false,
          ...p,
        });
      }
      save();
    };
    return updateAuth;
  }, []);

  const requireToken = React.useCallback(async () => {
    const { accessToken } = jwtApiContext;
    if (accessToken) {
      return accessToken;
    } else {
      const token = await GetAnonymousTokenApi(rawAuth.deviceId!);
      await updateAuth(token, null);
      return token;
    }
  }, []);

  React.useEffect(() => {
    jwtApiContext.rejectToken = async () => {
      const { accessToken, refreshToken } = await tryRefresh();
      if (accessToken && refreshToken) {
        updateAuth(accessToken, refreshToken);
        return accessToken;
      } else {
        updateAuth();
        return undefined;
      }
    };
    jwtApiContext.requireToken = requireToken;
    load();
    updateAuth(rawAuth.accessToken, rawAuth.refreshToken, false);
  }, []);

  // 一开始 auth 还没有加载好
  if (auth === undefined) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={auth}>
      <Dialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)}>
        <DialogTitle>请重新登录</DialogTitle>
        <DialogContent>
          <DialogContentText>
            已经好久没有验证过你的密码啦，为安全起见，请重新登录~
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowLoginDialog(false)}>退出登录</Button>
          <Button
            color="warning"
            onClick={() => {
              login();
              setShowLoginDialog(false);
            }}
            autoFocus
          >
            重新登录
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </AuthContext.Provider>
  );
};
