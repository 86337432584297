import { IMapFile } from '@/types/cocos/MazeRover/Map.d';

export type CodeLanguageType = 'cpp' | 'python' | 'scratch';

export enum CollectionItemType {
  Empty,
  Level,
  Paper,
  Slide,
  Video,
  OJ,
  Questions,
}

export enum SubProblemType {
  Single = 'single',
}

export interface ILevelCardInfo {
  supportedLanguages: CodeLanguageType[];
  defaultLanguage?: CodeLanguageType;
  allowedSnippets?: string[];
  cocosHTMLUri: string;
  codeRunnerTemplate: string;
  showSnippetBar: boolean;
  defaultCode: Record<string, string>;
  description: Record<string, string>;
  tips: Record<string, string[]>;
  title: string;
  mapData: IMapFile | string;
}

export interface IQustionSubProblem {
  content: string;
  options: [string, boolean][];
  explanation: string;
  explanationVideo?: string;
  type: SubProblemType;
  optionMap: number[];
  optionMapRevert: number[];
}

export interface IQuestionInfo {
  title?: string;
  content?: string;
  explanation?: string;
  subQuestions: IQustionSubProblem[];
}

export interface IPaperInfo {
  title: string;
  text: string;
}

interface CollectionSummaryItem {
  id: string;
  title: string;
  preface?: string;
  permission?: string;
}

export interface ICollectionCardBase {
  id: string;
  level: number;
  title: string;
  type: CollectionItemType;
  rawId: string;
  permission?: string;
}

export interface ICollectionEmptyCard extends ICollectionCardBase {
  type: CollectionItemType.Empty;
}

export interface ICollectionGameLevelCard extends ICollectionCardBase {
  type: CollectionItemType.Level;
  language: CodeLanguageType[];
  defaultLanguage?: CodeLanguageType;
  allowedSnippets?: string[];
}

export interface ICollectionOJCard extends ICollectionCardBase {
  type: CollectionItemType.OJ;
}

export interface ICollectionQuestionsCard extends ICollectionCardBase {
  type: CollectionItemType.Questions;
  questions: string[];
  scores: Record<string, number[]>;
}

export interface ICollectionVideoCard extends ICollectionCardBase {
  type: CollectionItemType.Video;
  url: string;
}

export interface ICollectionPaperCard extends ICollectionCardBase {
  type: CollectionItemType.Paper;
  title: string;
}

export interface ICollectionSlideCard extends ICollectionCardBase {
  type: CollectionItemType.Slide;
  url: string;
}

export type ICollectionCard =
  | ICollectionEmptyCard
  | ICollectionGameLevelCard
  | ICollectionVideoCard
  | ICollectionSlideCard
  | ICollectionOJCard
  | ICollectionQuestionsCard
  | ICollectionPaperCard;

export interface ICollection {
  title: string;
  preface?: string;
  permission?: string;
  toc: ICollectionCard[];
}
