import React from 'react';
import { usePartner } from '@daimaxiagu/redux-partner';

import LevelV2 from './v2';
import { LevelCardPartner } from './partner';
import {
  ILevelCardCodePersistance,
  MemoryCodePersistance,
} from './codePersistance';

import { useLevel } from '@/utils/api/gameCard';
import { mergeLevelData } from '@/utils/parser/level';
import { ICollectionGameLevelCard } from '@/types/data.d';
import RetriableLoadingMask from '@/components/LoadingMask/RetriableLoadingMask';

export interface ILevelViewProps {
  levelId: string;
  codePersistance?: ILevelCardCodePersistance;
  readonly?: boolean;
  showCreateBattle?: boolean;
  setTitle?: (title: string) => void;
  collectionItem?: ICollectionGameLevelCard;
  hideCocos?: boolean;
  debugCocosUri?: string;
  hasNextLevel?: boolean;
  hasLastLevel?: boolean;
  onMount?: (partner: LevelCardPartner) => (() => void) | void;
}

const LevelView = React.memo<ILevelViewProps>(
  ({
    levelId,
    collectionItem,
    hideCocos,
    debugCocosUri,
    hasNextLevel = false,
    hasLastLevel = false,
    codePersistance = new MemoryCodePersistance(),
    readonly = true,
    showCreateBattle = true,
    onMount,
  }) => {
    const partner = usePartner(() => new LevelCardPartner(codePersistance));
    const data = partner.useSelector(state => state.data);
    const language = partner.useSelector(state => state.language);
    const [level, error, loading, , retry] = useLevel(levelId, [levelId]);

    React.useEffect(() => {
      if (partner && onMount) {
        return onMount(partner);
      }
      return () => {
        //
      };
    }, [partner, onMount]);

    React.useEffect(() => {
      if (language === 'python') {
        partner.preloadPyodide();
      }
    }, [language]);

    React.useEffect(() => {
      partner.codePersistance = codePersistance;
    }, [codePersistance, partner]);

    React.useEffect(() => {
      partner.setShowBattleButton(showCreateBattle);
    }, [showCreateBattle, partner]);

    React.useEffect(() => {
      partner.setReadonlyMode(readonly);
    }, [readonly, partner]);

    React.useEffect(() => {
      if (level) {
        partner.load(
          levelId,
          collectionItem ? mergeLevelData(level, collectionItem) : level,
        );
      } else if (error) {
        partner.unload();
      }
    }, [level, error]);

    return (
      <RetriableLoadingMask
        error={error}
        loading={loading}
        retry={retry}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {data ? (
          <LevelV2
            partner={partner}
            hideCocos={hideCocos}
            debugCocosUri={debugCocosUri}
            hasNextLevel={hasNextLevel}
            hasLastLevel={hasLastLevel}
          />
        ) : (
          <></>
        )}
      </RetriableLoadingMask>
    );
  },
);

export default LevelView;
