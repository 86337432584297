import { HOST } from '@daimaxiagu/micro-frontend-provider';

export const transitionStyle = `opacity 400ms ${[
  'box-shadow',
  'border-color',
  'color',
]
  .map(style => `${style} 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`)
  .join(',')}`;

export const DefaultCode = {
  cpp: [
    '#include <bits/stdc++.h>',
    'using namespace std;',
    '',
    'int main(void) {',
    '    <%cursor%>',
    '    return 0;',
    '}',
  ].join('\n'),
  python: '',
  scratch:
    '<xml xmlns="http://www.w3.org/1999/xhtml"><variables></variables><block type="event_whenflagclicked" id="?T~YyxQJ9bYh9x#kIDqk" x="0" y="0"></block></xml>',
  go: 'func main() {\n    \n}',
};

export const COCOS_INDEX: Record<string, string> = {
  MazeRover: `https://${HOST}/cocos/mazerover/index.html`,
  MazeRoverV2: `https://${HOST}/cocos/mazerover/index.html`,
  // MazeRoverV2: `http://127.0.0.1:8001/`,
};
export const CODERUNNER_API_BASE = `https://${HOST}/api/code-runner`;
export const CODERUNNER_WS_BASE = `wss://${HOST}/api/code-runner`;
export const CLASSROOM_WS_BASE = `wss://${HOST}/ws/classroom`;
export const BATTLE_WS_BASE = {
  Simple1V1RoomV0: `wss://${HOST}/ws/battle/simple1v1/v0`,
};
export const OSS_RESOURCE_BASE = 'https://oss.daimaxiagu.com';
export const OSS_PUBLIC_ASSETS_BASE = 'https://oss.daimaxiagu.com/public';
export const MEDIA_RESOURCE_BASE = 'https://media.daimaxiagu.com';
export const AGORA_TOKEN_API_BASE = `https://${HOST}/api/agora-token`;
export const VOD_TOKEN_API_BASE = `https://${HOST}/api/volcengin-token`;
export const HIGHLIGHT_API_BASE = `https://${HOST}/api/highlight`;
export const WEIXIN_JSAPI_TICKET_API_BASE = `https://${HOST}/api/wx`;
export const WECHAT_API_TOKEN_BASE = `https://${HOST}/api/wechat-api-token`;
export const STUDENT_MANAGER_API_BASE = `https://${HOST}/api/student-manager`;
export const IDE_JUDGER_API_BASE = `https://${HOST}/api/ide-judger`;
export const SUBMISSION_API_BASE = `https://${HOST}/api/submission-recorder/v1`;
export const WASM_COMPILER_API_BASE = `https://${HOST}/api/compile2wasm`;
// export const OJ_API_BASE = isLocalhost
//   ? `http://${window.location.hostname}/api/oj`
//   : `https://${HOST}/api/oj`;
export const OJ_API_BASE = '/api/oj';

export const VOLC_VOD_CONFIG = {
  SPACE_NAME: 'daimaxiagu-vod',
  APP_ID: 409462,
};
export const WEIXIN_APP_ID = 'wx5ae7808267e29706';
