import React from 'react';
import { Helmet } from '@modern-js/runtime/head';
import { UserGateKeeper } from '@daimaxiagu/micro-frontend-provider';
import SlideView from '@/components/card/slide/SlideView';

const SlideIndex = ({ match: { params } }: any) => {
  const [title, setTitle] = React.useState<string>('加载中...');
  return (
    <UserGateKeeper
      object="daimaxiagu:single-card-page.slide"
      action="visit"
      goBack
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SlideView
        slideId={params?.slideId.trim() ?? ''}
        setTitle={setTitle}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      />
    </UserGateKeeper>
  );
};

export default SlideIndex;
