/* eslint-disable max-lines, complexity */
import once from 'lodash/once';
import AlertTitle from '@mui/material/AlertTitle';
import { Partner } from '@daimaxiagu/redux-partner';
import { emitter, Emitter } from '@daimaxiagu/emitter';
import { api, DEV } from '@daimaxiagu/micro-frontend-provider';
import {
  PyodideVM,
  CWebAssemblyVM,
  IPyodideRunner,
  IWasmRunner,
  ILoadCWasmOptions,
  ILoadPyodideOptions,
} from '@daimaxiagu/wasm-runner';

import { CommandEmitters } from '../../EmbeddedCocos/IFrame';
import { ILevelCardCodePersistance } from './codePersistance';
// import { checkError } from './v2/checkError';
import { workerPrefix, pythonCodePrefix } from './v2/runner';
import { SnackBarHandler } from './v2/SnackBarProvider';

import { ILevelCardInfo, CodeLanguageType, ICollection } from '@/types/data.d';
import { emccCompileApi } from '@/utils/api/emcc';

import {
  IMixCodeEditorInstance,
  MixCodeEditorType,
} from '@/components/views/codeeditor';

export enum GameState {
  Loading = 'Loading',
  Ready = 'Ready',
  Compiling = 'Compiling',
  Running = 'Running',
  Error = 'Error',
  Runned = 'Runned',
}

interface ILevelCardState {
  id: string;
  data?: ILevelCardInfo;
  readonly: boolean;
  language?: CodeLanguageType;
  showTip: boolean;
  showBattleButton: boolean;
  gameState: GameState;
}

type CocosEventTypes = {
  ResourceDownloadComplete: void;
  NextLevel: void;
  Share: void;
  Restart: void;
  PopupMessage: {
    title?: string;
    text?: string;
    duration?: number;
    severity: 'success' | 'info' | 'warning' | 'error';
  };
};

type CocosCommandTypes = {
  DispatchEmulatorEvents: {
    props: {
      events: [string, any][];
    };
    return: Promise<void>;
  };
  ImportWorld: {
    props: { plain: Record<string, any> };
    return: Promise<void>;
  };
  ExportWorld: {
    props: void;
    return: Promise<Record<string, any>>;
  };
  LoadMap: {
    props: {
      map: Record<string, any>;
    };
    return: Promise<void>;
  };
  SetSelfId: {
    props: {
      id: string;
    };
    return: Promise<void>;
  };
};

interface IEditorChangeEvent {
  contentChange: boolean;
  cursor: number;
  content: string;
  editor: MixCodeEditorType;
  instance: IMixCodeEditorInstance;
  time: number;
}

interface LevelCardEvents {
  CardReady: void;
  CardPass: { cardId: string };
  CardSubmitted: {
    cardId: string;
    cardName: string;
    status: string;
    score: number;
    language: 'cpp' | 'python' | 'scratch';
    code: string;
    meta?: Record<string, any>;
  };
  CocosReady: void;
  LevelChange: { id: string; data: ILevelCardInfo };
  EditorReady: void;
  EditorChange: IEditorChangeEvent;
  ChangeToNextCard: void;
  ChangeToLastCard: void;
  LanguageChanged: {
    cardId: string;
    previous?: CodeLanguageType;
    defaultCode: string;
    current: CodeLanguageType;
    cancelPersistance: boolean;
  };
}

const getEmulatorScriptApi = once(
  api<void, string>(
    _ => ({
      method: 'GET',
      url: 'https://oss.daimaxiagu.com/frontend/public/mazerover-emulator/index.min.js',
      responseType: 'blob',
    }),
    ({ data }) => URL.createObjectURL(data as Blob),
    undefined,
    false,
  ),
);

const pyodideVM = PyodideVM(
  'https://public-oss.daimaxiagu.com/public/pyodide/v0.21.3/pyodide.js',
  'https://oss.daimaxiagu.com/frontend/public/pyodide/worker/worker.min.js',
);
let pyodideRunnerPreloaded = false;
const cwasmVM = CWebAssemblyVM();

export class LevelCardPartner extends Partner<
  ILevelCardState,
  LevelCardEvents
> {
  public readonly cocosEvent: Emitter<CocosEventTypes> = emitter();

  public cocosCommand?: CommandEmitters<CocosCommandTypes>;

  public cocosIframe?: HTMLIFrameElement;

  public snackBar?: SnackBarHandler;

  public editor?: IMixCodeEditorInstance;

  public collectionId?: string;

  public collectionData?: ICollection;

  public codePersistance: ILevelCardCodePersistance;

  private _lastSetCodeTime: number = 0;

  private _resourceLoadCompleteResolve?: () => void;

  private _resourceLoadComplete: Promise<void> = new Promise<void>(resolve => {
    this._resourceLoadCompleteResolve = resolve;
  });

  private _mapData: any;

  private _lastRandomMapIndex: number = -1;

  private _lastLevelData?: ILevelCardInfo;

  private _pyodideRunner?: IPyodideRunner;

  private _cwasmRunner?: IWasmRunner;

  private _runnerOptions: ILoadCWasmOptions & ILoadPyodideOptions = {};

  private _emulationState: {
    terminated: boolean;
    eventQueue: [string, any][][];
    playId: number;
  } = {
    terminated: false,
    eventQueue: [],
    playId: 0,
  };

  constructor(codePersistance: ILevelCardCodePersistance) {
    super('card.game-level', {
      id: '',
      readonly: true,
      showTip: false,
      showBattleButton: false,
      gameState: GameState.Loading,
    });
    this.codePersistance = codePersistance;

    this.cocosEvent.on('ResourceDownloadComplete', () => {
      if (this._resourceLoadCompleteResolve) {
        this._resourceLoadCompleteResolve();
        this._resourceLoadCompleteResolve = undefined;
      } else {
        this.loadMap();
      }
    });

    this.cocosEvent.on('Restart', () => {
      this.resetGame();
    });

    this.cocosEvent.on('NextLevel', () => {
      this.setGameState(GameState.Loading);
      this.emit('ChangeToNextCard', undefined);
    });

    this.on(
      'LanguageChanged',
      async ({ cardId, current, cancelPersistance, defaultCode }) => {
        if (cancelPersistance) {
          return;
        }
        const [code, cursor] = (await this.codePersistance.get({
          cardId,
          language: current,
        })) ?? [defaultCode, undefined];
        this.setCode(code, cursor);
      },
    );

    this.on('EditorChange', ({ contentChange, cursor, content, time }) => {
      if (time < this._lastSetCodeTime) {
        return;
      }
      const { id, language } = this.getState();
      if (contentChange) {
        this.codePersistance.set({
          code: content,
          cursor,
          cardId: id,
          language: language!,
        });
      } else {
        this.codePersistance.setCursor({
          cursor,
          cardId: id,
          language: language!,
        });
      }
    });
  }

  mountCocos(commandSender: CommandEmitters<any>, iframe: HTMLIFrameElement) {
    this.cocosCommand = commandSender;
    this.cocosIframe = iframe;
    this.setGameState(GameState.Loading);
    this.emit('CocosReady', undefined);
  }

  unmountCocos() {
    this.cocosCommand = undefined;
    this.cocosIframe = undefined;
    this.setGameState(GameState.Loading);
  }

  setReadonlyMode(readonly: boolean) {
    this.updateState(state => (state.readonly = readonly));
  }

  bindCollection(collectionId: string, collectionData: ICollection) {
    this.collectionId = collectionId;
    this.collectionData = collectionData;
  }

  async load(id: string, data: ILevelCardInfo) {
    const language = await this.codePersistance.getLanguage({
      cardId: id,
      cardData: data,
    });
    let previousLanguage: CodeLanguageType | undefined;
    this.updateState(state => {
      state.id = id;
      state.data = data;
      previousLanguage = state.language;
      state.language = language;
    });
    this.setGameState(GameState.Loading);
    const [code, cursor] = (await this.codePersistance.get({
      cardId: id,
      language,
    })) ?? [data.defaultCode[language] ?? '', undefined];
    if (previousLanguage !== language) {
      this.emit('LanguageChanged', {
        cardId: id,
        previous: previousLanguage,
        current: language,
        cancelPersistance: true,
        defaultCode: '',
      });
    }
    this.setCode(code, cursor);
    this._lastLevelData = data;
    this._lastRandomMapIndex = -1;
    await this.loadMap(data);
    this.emit('LevelChange', { id, data });
  }

  randomizeMap(data: ILevelCardInfo) {
    if (!Array.isArray(data.mapData)) {
      this._mapData = data.mapData;
      this._lastRandomMapIndex = -1;
    } else {
      this._lastRandomMapIndex =
        this._lastRandomMapIndex === -1
          ? Math.floor(Math.random() * data.mapData.length)
          : (Math.floor(Math.random() * (data.mapData.length - 1) + 1) +
              this._lastRandomMapIndex) %
            data.mapData.length;
      this._mapData = data.mapData[this._lastRandomMapIndex];
    }
  }

  async loadMap(data: ILevelCardInfo = this._lastLevelData!) {
    this._emulationState.playId++;
    this.setGameState(GameState.Loading);
    const emulatorUrl = await getEmulatorScriptApi();
    await this._resourceLoadComplete;
    this.randomizeMap(data);
    const mapString = JSON.stringify(this._mapData);
    const events = await new Promise<[string, any][]>((resolve, reject) => {
      try {
        const url = URL.createObjectURL(
          new Blob(
            [
              workerPrefix(emulatorUrl),
              `\nglobalThis.postMessage({ target: 'loadMap', events: globalThis.loadMap(${mapString}, 'player|001') });`,
            ],
            {
              type: 'application/javascript',
            },
          ),
        );
        const worker = new Worker(url);
        worker.onmessage = message => {
          if (message.data.target === 'loadMap') {
            resolve(
              message.data.events.map(([, event, payload]: any) => [
                event,
                payload,
              ]),
            );
            worker.terminate();
            URL.revokeObjectURL(url);
          }
        };
        worker.onerror = error => {
          reject(error);
          worker.terminate();
          URL.revokeObjectURL(url);
        };
      } catch (e) {
        reject(e);
      }
    });
    await this.cocosCommand!.LoadMap({ map: this._mapData });
    await this.cocosCommand!.SetSelfId({ id: 'player|001' });
    await this.cocosCommand!.DispatchEmulatorEvents({ events });
    this.setGameState(GameState.Ready);
    this.emit('CardReady', undefined);
  }

  unload() {
    this.updateState(state => {
      state.id = '';
      delete state.data;
      delete state.language;
    });
  }

  onDestory() {
    this.unload();
    this.unmountCocos();
    this.editor = undefined;
    this.off();
    this.cocosEvent.off();
  }

  mountEditor(editor: IMixCodeEditorInstance) {
    this.editor = editor;
    this.emit('EditorReady', undefined);
  }

  unmountEditor() {
    this.editor = undefined;
  }

  editorChange(event: IEditorChangeEvent) {
    this.emit('EditorChange', event);
  }

  changeToLastCard() {
    this.emit('ChangeToLastCard', undefined);
  }

  changeToNextCard() {
    this.emit('ChangeToNextCard', undefined);
  }

  setLanguage(language: CodeLanguageType, cancelPersistance = false) {
    this.updateState(state => {
      if (state.language !== language) {
        this.emit('LanguageChanged', {
          cardId: state.id,
          previous: state.language,
          current: language,
          cancelPersistance,
          defaultCode: cancelPersistance
            ? ''
            : state.data!.defaultCode[language] ?? '',
        });
        state.language = language;
      }
      this.codePersistance.setLanguage({
        language,
        cardData: state.data!,
        cardId: state.id,
      });
    });
  }

  setShowBattleButton(showBattleButton: boolean) {
    this.updateState(state => (state.showBattleButton = showBattleButton));
  }

  async resetGame() {
    await this.loadMap();
  }

  async abortGame() {
    this._cwasmRunner?.abort?.();
    this._pyodideRunner?.abort?.();
  }

  async resetAndRunGame() {
    await this.resetGame();
    await this.runGame();
  }

  clearError() {
    this.setGameState(GameState.Runned);
    this.snackBar?.clear?.();
  }

  setGameState(gameState: GameState) {
    this.updateState(state => (state.gameState = gameState));
  }

  showTip() {
    this.updateState(state => (state.showTip = true));
  }

  hideTip() {
    this.updateState(state => (state.showTip = false));
  }

  preloadPyodide() {
    if (!pyodideRunnerPreloaded) {
      pyodideVM.preloadRunnerScript();
      pyodideRunnerPreloaded = true;
    }
  }

  async runGame() {
    const code = this.editor?.getCode?.();
    const reduxState = this.getState();
    if (
      code === undefined ||
      reduxState.language === undefined ||
      this.cocosCommand === undefined
    ) {
      return;
    }

    // 相关信息暂存，以免切换关卡后执行导致提交信息错位
    const cardId = reduxState.id;
    const cardName = reduxState.data!.title;
    const language_ = reduxState.language;
    const language = language_ === 'scratch' ? 'python' : reduxState.language;

    let initEvents: [string, any][];
    let stage: 'compile' | 'run' | 'play' = 'compile';
    this.setGameState(GameState.Compiling);
    this._emulationState.eventQueue = [];
    this._emulationState.terminated = false;
    this._runnerOptions.workerPrefix = workerPrefix(
      await getEmulatorScriptApi(),
      language === 'python',
      DEV,
    );
    this._runnerOptions.externalFunctions = {
      emulatorEvents: (
        events: [number, string, any][],
        terminated: boolean,
      ) => {
        this._emulationState.terminated = terminated;
        for (const [tick, event, payload] of events) {
          while (this._emulationState.eventQueue.length <= tick) {
            this._emulationState.eventQueue.push([]);
          }
          this._emulationState.eventQueue[tick].push([event, payload]);
        }
      },
    };
    this._runnerOptions.workerSuffix = undefined;
    this.randomizeMap(this._lastLevelData!);
    try {
      if (language === 'cpp') {
        this.setGameState(GameState.Compiling);
        const { workerScript, wasm } = await emccCompileApi({
          code,
          template: 'mazerover',
          noWorkerScript: false,
          maxMemory: 268435456,
        });
        stage = 'run';
        this.setGameState(GameState.Running);
        this._runnerOptions.workerSuffix = 'out = globalThis.stdout;';
        const runner = await cwasmVM.load(
          wasm,
          workerScript,
          this._runnerOptions,
        );
        this._cwasmRunner = runner;
        await runner.call('setMaxCommand', 1024);
        initEvents = (
          await runner.call('loadMap', this._mapData, 'player|001')
        ).map(([, event, payload]: any) => [event, payload]);
        try {
          await runner.run();
        } catch (err) {
          if (!this._emulationState.terminated) {
            runner.abort();
            throw err;
          }
        }
        runner.abort();
      } else {
        if (this._pyodideRunner?.terminated === true) {
          this._pyodideRunner = undefined;
        }
        const runner =
          this._pyodideRunner ?? (await pyodideVM.load(this._runnerOptions));
        this._pyodideRunner = runner;
        stage = 'run';
        runner.clearInput();
        runner.reset();
        await runner.call('setMaxCommand', 1024);
        initEvents = (
          await runner.call('loadMap', this._mapData, 'player|001')
        ).map(([, event, payload]: any) => [event, payload]);
        try {
          await runner.run(pythonCodePrefix);
          await runner.call('getStdout');
          await runner.run(code);
        } catch (err) {
          if (!this._emulationState.terminated) {
            throw err;
          }
        }
      }

      // 上面编译、运行用了时间，这里如果切关了，就不要继续
      if (cardId === reduxState.id) {
        // 检查通关
        let cardPass = false;
        for (const events of this._emulationState.eventQueue) {
          for (const [event, payload] of events) {
            // eslint-disable-next-line max-depth
            if (event === 'PlayerWin' && payload.player === 'player|001') {
              cardPass = true;
              break;
            }
          }
          if (cardPass) {
            break;
          }
        }
        if (cardPass) {
          this.emit('CardPass', { cardId });
        }
        this.emit('CardSubmitted', {
          cardId,
          cardName,
          status: cardPass ? 'AC' : 'WA',
          score: cardPass ? 100 : 0,
          language: language_,
          code,
        });

        // 播放闯关动画
        stage = 'play';
        const playId = ++this._emulationState.playId;
        await this.cocosCommand.LoadMap({ map: this._mapData });
        await this.cocosCommand.SetSelfId({ id: 'player|001' });
        await this.cocosCommand.DispatchEmulatorEvents({ events: initEvents });
        await new Promise<void>((resolve, reject) => {
          const id = setInterval(async () => {
            try {
              const events = this._emulationState.eventQueue.shift();
              if (events && playId === this._emulationState.playId) {
                await this.cocosCommand!.DispatchEmulatorEvents({
                  events,
                });
              } else {
                clearInterval(id);
                resolve();
              }
            } catch (e) {
              clearInterval(id);
              reject(e);
            }
          }, 1000);
        });
        if (playId === this._emulationState.playId) {
          this.cocosEvent.emit('PopupMessage', {
            title: '运行完毕',
            duration: 2000,
            severity: 'success',
          });
          this.setGameState(GameState.Runned);
        }
      }
    } catch (_error: any) {
      this.setGameState(GameState.Error);
      console.error(_error);
      let error: string;
      if (language === 'python') {
        const errors = String(_error).split('pyodide.JsException: Error: ');
        if (errors.length > 1) {
          error = errors.pop() ?? '';
        } else if (errors[0].startsWith('PythonError:')) {
          const _ = errors[0].split('File "<exec>"');
          if (_.length > 1) {
            error = `PythonError: Traceback (most recent call last):\n  File "<exec>"${_[1]}`;
          } else {
            error = _[0];
          }
        } else {
          error = errors[0];
        }
      } else {
        const errors = String(_error)
          .replace(/^Error:\s+/, '')
          .split('source.cpp:');
        errors.shift();
        const result = [];
        for (const error of errors) {
          const [line, ...others] = error.split(':');
          result.push(`${Number(line) - 1}:${others.join(':')}`);
        }
        error = `source.cpp:${result.join('source.cpp:')}`;
      }
      switch (stage) {
        case 'compile': {
          this.snackBar?.push(
            <>
              <AlertTitle>编译错误</AlertTitle>
              {/* {checkError(
                  reduxState.language,
                  offset ?? 0,
                  'compile',
                  message ?? '',
                ).map(({ line, column, message, type }) => (
                  <p key={message}>{`${line <= 0 ? '' : `${line}行`}${
                    column <= 0 ? '' : `${column}列`
                  }${line > 0 && column > 0 ? ':\n' : ''}${
                    type ? `[${type}] ` : ''
                  }${message}`}</p>
                ))} */}
              <pre>{error}</pre>
            </>,
            {
              duration: -1,
              severity: 'warning',
            },
          );
          this.emit('CardSubmitted', {
            cardId: reduxState.id,
            cardName: reduxState.data!.title,
            status: 'CE',
            score: 0,
            language: reduxState.language,
            code,
            meta: { error },
          });
          break;
        }
        case 'run': {
          this.snackBar?.push(
            <>
              <AlertTitle>运行错误</AlertTitle>
              {/* {checkError(
                  reduxState.language,
                  offset ?? 0,
                  'runtime',
                  message ?? '',
                ).map(({ line, column, message, type }) => (
                  <p key={message}>{`${line <= 0 ? '' : `${line}行`}${
                    column <= 0 ? '' : `${column}列`
                  }${line > 0 && column > 0 ? ':\n' : ''}${
                    type ? `[${type}] ` : ''
                  }${message}`}</p>
                ))} */}
              <pre>{error}</pre>
            </>,
            {
              duration: -1,
              severity: 'warning',
            },
          );
          this.emit('CardSubmitted', {
            cardId: reduxState.id,
            cardName: reduxState.data!.title,
            status: 'RE',
            score: 0,
            language: reduxState.language,
            code,
            meta: { error },
          });
          break;
        }
        case 'play': {
          this.cocosEvent.emit('PopupMessage', {
            title: 'Cocos错误',
            text: error,
            duration: 5000,
            severity: 'error',
          });
          break;
        }
        default: {
          //
        }
      }
    }
  }

  async setCode(code: string, cursor?: number) {
    if (this.editor === undefined) {
      await this.waitFor('EditorReady');
    }
    this._lastSetCodeTime = Date.now();
    this.editor!.setValue(code, cursor);
  }
}
/* eslint-enable max-lines, complexity */
