import { api } from '@daimaxiagu/micro-frontend-provider';
import { parseQuestion } from '@/utils/parser/question';
import { parsePaper } from '@/utils/parser/paper';
import { parseLevel } from '@/utils/parser/level';
import { parseCollection } from '@/utils/parser/collection';
import { OSS_RESOURCE_BASE } from '@/utils/config';
import {
  ILevelCardInfo,
  IPaperInfo,
  IQuestionInfo,
  ICollection,
  CollectionSummaryItem,
} from '@/types/data.d';

export const getJsonPathFromId = (id: string) =>
  id
    .split('.')[0]
    .replace(/[^a-zA-Z0-9-_]/g, '')
    .replace(/-/g, '/');

const decorateConfig = (url: string) => ({
  baseURL: OSS_RESOURCE_BASE,
  url,
  method: 'GET',
  cache: {
    interpretHeader: false,
    ttl: 86400000,
  },
});

export const useCollectionSummary = api<void, CollectionSummaryItem[]>(
  _ => decorateConfig('/collectionsInfo.json'),
  ({ data }) => data,
  undefined,
  false,
).createMemoHook();

export const useCollection = api<string, ICollection>(
  collectionId =>
    decorateConfig(`/collections/${getJsonPathFromId(collectionId)}.json`),
  ({ data }) => parseCollection(data),
  undefined,
  false,
).createMemoHook();

export const useLevel = api<string, ILevelCardInfo>(
  levelId => decorateConfig(`/levels/${getJsonPathFromId(levelId)}.json`),
  ({ data }) => parseLevel(data),
  undefined,
  false,
).createMemoHook();

export const usePaper = api<string, IPaperInfo>(
  paperId => decorateConfig(`/papers/${getJsonPathFromId(paperId)}.md`),
  ({ data }) => parsePaper(data),
  undefined,
  false,
).createMemoHook();

const questionApi = api<string, IQuestionInfo>(
  questionId =>
    decorateConfig(`/questions/${getJsonPathFromId(questionId)}.json`),
  ({ data }) => parseQuestion(data),
  undefined,
  false,
);

export const useQuestion = questionApi.createMemoHook();
export const useQuestions = questionApi.createBatchMemoHook();

export const useSlide = api<string, string>(
  slideId => decorateConfig(`/slides/${getJsonPathFromId(slideId)}.html`),
  ({ config: { url } }) => `${OSS_RESOURCE_BASE}${url!}`,
  undefined,
  false,
).createMemoHook();
