import { api } from '@daimaxiagu/micro-frontend-provider';
import { WASM_COMPILER_API_BASE } from '@/utils/config';

export const emccCompileApi = api<
  {
    code: string;
    template?: string;
    noWorkerScript?: boolean;
    optimizationLevel?: '0' | '1' | '2' | '3' | 'g' | 's' | 'z';
    maxMemory?: number;
  },
  {
    wasm: Blob;
    workerScript: string;
    compileMessage: string;
  }
>(
  ({
    code,
    noWorkerScript = false,
    optimizationLevel,
    template = 'empty',
    maxMemory,
  }) => ({
    method: 'post',
    data: {
      template,
      text: code,
      no_worker_script: noWorkerScript,
      optimization_level: optimizationLevel,
      max_memory: maxMemory,
    },
    baseURL: WASM_COMPILER_API_BASE,
    url: '/cpp',
    responseType: 'blob',
  }),
  async ({ data }) => {
    const data_ = data as Blob;
    const view = new DataView(await data_.arrayBuffer());
    const wasmLength = view.getInt32(0, false);
    const workerLength = view.getInt32(4, false);
    const wasm = data_.slice(12, 12 + wasmLength, 'application/wasm');
    const workerScript = await data_
      .slice(12 + wasmLength, 12 + wasmLength + workerLength)
      .text();
    const compileMessage = await data_
      .slice(12 + wasmLength + workerLength, data_.size)
      .text();
    return {
      wasm,
      workerScript,
      compileMessage,
    };
  },
  async ({ error, axiosError }) => {
    if (
      axiosError?.response?.data &&
      axiosError.response.data instanceof Blob
    ) {
      let detail = '';
      try {
        // eslint-disable-next-line prefer-destructuring
        detail = JSON.parse(await axiosError.response.data.text()).detail;
        const errors = detail
          .replace(/^Error: /, '')
          .split("emcc: error: '/app/emsdk");
        if (errors.length > 1) {
          errors.pop();
        }
        detail = errors
          .join("emcc: error: '/app/emsdk")
          .trim()
          .replace(/\/emcc-cache\//g, '/');
      } catch (_err) {
        throw error;
      }
      throw new Error(detail);
    } else {
      throw error;
    }
  },
  true,
);
