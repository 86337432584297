import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  IProvider,
  AuthContext,
  UtilsContext,
  jwtApiContext,
  useDefaultAvatarUrl,
} from '@daimaxiagu/micro-frontend-provider';
import { useModuleApps } from '@modern-js/plugin-garfish';

const MicroFrontend = ({ name }: { name: string }) => {
  const apps = useModuleApps();
  const App = apps[name];
  const theme = useTheme();
  const defaultAvatarUrl = useDefaultAvatarUrl();
  const _ = React.useMemo<IProvider['_']>(
    () =>
      ({
        defaultAvatarUrl,
        rejectToken: jwtApiContext.rejectToken,
        requireToken: jwtApiContext.requireToken,
      } as any),
    [],
  );
  const auth = React.useContext(AuthContext);
  const utils = React.useContext(UtilsContext);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '& > div': {
          width: '100%',
          height: '100%',
          '& > div': {
            width: '100%',
            height: '100%',
            '& > div[__garfishmockhtml__]': {
              width: '100%',
              height: '100%',
              '& > div[__garfishmockbody__]': {
                width: '100%',
                height: '100%',
              },
            },
          },
        },
      }}
    >
      <App
        _={_}
        utils={utils}
        auth={{
          ...auth,
          setToken: (token: string) => {
            if (!token) {
              auth.setToken();
            }
          },
          inMicroFrontend: true,
        }}
        theme={theme}
      />
    </Box>
  );
};

export default new Proxy<Record<string, React.FC>>(
  {},
  {
    get: (_target, name) =>
      typeof name === 'string'
        ? () => <MicroFrontend name={name} />
        : () => <></>,
  },
);
