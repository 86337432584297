import { validate, Schema } from 'jsonschema';
import { IPaperInfo } from '@/types/data.d';

const PaperJsonSchema: Schema = {
  type: 'string',
  required: true,
};

export const parsePaper = (paperInfoJsonLike: unknown): IPaperInfo => {
  validate(paperInfoJsonLike, PaperJsonSchema, { throwFirst: true });
  const paperText = paperInfoJsonLike as string;
  return {
    title: /^\s*##?[\t ]*([^\n]*)/.exec(paperText)?.[1]?.trim() ?? '无题',
    text: paperText,
  };
};
