import React from 'react';
import { SxProps } from '@mui/material';

import { usePaper } from '@/utils/api/gameCard';
import MarkdownBlock from '@/components/views/markdown/MarkdownBlock';
import RetriableLoadingMask from '@/components/LoadingMask/RetriableLoadingMask';

export interface IMarkdownViewProps {
  paperId: string;
  setTitle?: (title: string) => void;
  style?: SxProps;
}

export default React.memo<IMarkdownViewProps>(({ paperId, style = {} }) => {
  const [paper, error, loading, , retry] = usePaper(paperId, [paperId]);
  return (
    <RetriableLoadingMask
      error={error}
      loading={loading}
      retry={retry}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {paper ? <MarkdownBlock style={style} text={paper.text} /> : <></>}
    </RetriableLoadingMask>
  );
});
