import sha256 from 'sha256';
import jwtDecode from 'jwt-decode';
import { validate, Schema } from 'jsonschema';
import { v4, version as uuidVersion, validate as uuidValidate } from 'uuid';
import {
  UserType,
  VerifyTokenApi,
  STORAGE_DEVICEID_KEY,
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_HASHED_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
  STORAGE_HASHED_REFRESH_TOKEN_KEY,
} from '@daimaxiagu/micro-frontend-provider';

export const STORAGE_CASBIN_CACHE = (subject: string) =>
  `@daimaxiagu::casbin.${subject}`;
const STORAGE_NEWEST_TOKEN_TIME = '@daimaxiagu::newest-token-time';

const HASH_SALT =
  '0787b451c9c385f0ef78d45dc8d7ed7c3eca8798efbc464785385744d175e091';

interface IRawAuth {
  deviceId?: string;
  accessToken?: string;
  hashedAccessToken?: string;
  refreshToken?: string;
  hashedRefreshToken?: string;
  tokenTime?: number;
}

const JWTPayloadSchema: Schema = {
  type: 'object',
  required: true,
  properties: {
    owner: {
      type: 'string',
      required: true,
      enum: ['daimaxiagu'],
    },
    name: {
      type: 'string',
      required: true,
    },
    type: {
      type: 'string',
      required: true,
      enum: ['normal-user', 'anonymous-user', 'bot-user'],
    },
    displayName: {
      type: 'string',
      required: true,
    },
    avatar: {
      type: 'string',
      required: true,
    },
    isDefaultAvatar: {
      type: 'boolean',
      required: true,
    },
    signupApplication: {
      type: 'string',
      required: true,
      enum: ['daimaxiagu'],
    },
    scope: {
      type: 'string',
      required: true,
    },
    iss: {
      type: 'string',
      required: true,
    },
    sub: {
      type: 'string',
      required: true,
    },
    aud: {
      required: true,
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'string',
      },
    },
    exp: {
      type: 'number',
      required: true,
    },
    nbf: {
      type: 'number',
      required: true,
    },
    iat: {
      type: 'number',
      required: true,
    },
    jti: {
      type: 'string',
      required: true,
    },
  },
};

export const jwtPayloadAvaliableProperties: (keyof IJWTPayload)[] = [
  'displayName',
  'email',
  'phone',
  'location',
  'idCardType',
  'idCard',
  'homepage',
  'bio',
  'region',
  'language',
  'gender',
  'birthday',
  'education',
  'github',
  'google',
  'qq',
  'wechat',
  'facebook',
  'dingtalk',
  'weibo',
  'gitee',
  'linkedin',
  'wecom',
  'lark',
  'gitlab',
  'adfs',
  'baidu',
  'alipay',
  'casdoor',
  'infoflow',
  'apple',
  'azuread',
  'slack',
  'steam',
  'bilibili',
  'okta',
  'douyin',
];
export interface IJWTPayload {
  owner: string;
  name: string;
  type: UserType;
  displayName: string;
  avatar: string;
  isDefaultAvatar: boolean;
  email: string;
  emailVerified: boolean;
  phone: string;
  location: string;
  address: string[];
  idCardType: string;
  idCard: string;
  homepage: string;
  bio: string;
  region: string;
  language: string;
  gender: string;
  birthday: string;
  education: string;
  github: string;
  google: string;
  qq: string;
  wechat: string;
  facebook: string;
  dingtalk: string;
  weibo: string;
  gitee: string;
  linkedin: string;
  wecom: string;
  lark: string;
  gitlab: string;
  adfs: string;
  baidu: string;
  alipay: string;
  casdoor: string;
  infoflow: string;
  apple: string;
  azuread: string;
  slack: string;
  steam: string;
  bilibili: string;
  okta: string;
  douyin: string;
  properties: Record<string, string>;
  signupApplication: string;
  scope: string;
  iss: string;
  sub: string;
  aud: string[];
  exp: number;
  nbf: number;
  iat: number;
  jti: string;
}

export const hashify = (token: string) => sha256(`${token}.${HASH_SALT}`);

export const rawAuth: IRawAuth = {};

export const checkJWT = async (token: string, verify = false) => {
  try {
    const payload = jwtDecode<IJWTPayload>(token);
    if (verify) {
      validate(payload, JWTPayloadSchema, { throwFirst: true });
      // 先本地再远程验证
      const t = await VerifyTokenApi(token);
      return t ? payload : undefined;
    } else {
      return payload;
    }
  } catch (e) {
    console.error('[check jwt]', { token, result: e });
    return undefined;
  }
};

let autoSave = false;

export const load = () => {
  let deviceId = localStorage.getItem(STORAGE_DEVICEID_KEY);
  let accessToken = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
  let hashedAccessToken = localStorage.getItem(STORAGE_HASHED_ACCESS_TOKEN_KEY);
  let refreshToken = localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY);
  let hashedRefreshToken = localStorage.getItem(
    STORAGE_HASHED_REFRESH_TOKEN_KEY,
  );

  // uuid 验证
  if (
    deviceId === null ||
    !uuidValidate(deviceId) ||
    uuidVersion(deviceId) !== 4
  ) {
    deviceId = v4();
  }
  rawAuth.deviceId = deviceId;

  // Access Token 验证
  if (
    accessToken === null ||
    hashedAccessToken === null ||
    hashify(accessToken) !== hashedAccessToken
  ) {
    accessToken = null;
    hashedAccessToken = null;
  } else {
    rawAuth.accessToken = accessToken;
    rawAuth.hashedAccessToken = hashedAccessToken;
  }

  // Refresh Token 验证
  if (
    refreshToken === null ||
    hashedRefreshToken === null ||
    hashify(refreshToken) !== hashedRefreshToken
  ) {
    refreshToken = null;
    hashedRefreshToken = null;
  } else {
    rawAuth.refreshToken = refreshToken;
    rawAuth.hashedRefreshToken = hashedRefreshToken;
  }

  // 定期保存防篡改
  if (!autoSave) {
    setInterval(save, 1000);
    autoSave = true;
  }
};

export const save = () => {
  const {
    deviceId,
    accessToken,
    hashedAccessToken,
    refreshToken,
    hashedRefreshToken,
  } = rawAuth;
  // 防止多个页面相互写，让 jwt 签发最晚的来更新
  const t = Number(localStorage.getItem(STORAGE_NEWEST_TOKEN_TIME) ?? '0');
  if (rawAuth.tokenTime === undefined || rawAuth.tokenTime < t) {
    return;
  }
  let i = 3;
  while (i-- > 0) {
    try {
      localStorage.setItem(STORAGE_NEWEST_TOKEN_TIME, `${rawAuth.tokenTime}`);
      if (deviceId) {
        localStorage.setItem(STORAGE_DEVICEID_KEY, deviceId);
      } else {
        localStorage.removeItem(STORAGE_DEVICEID_KEY);
      }
      if (accessToken && hashedAccessToken) {
        localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(
          STORAGE_HASHED_ACCESS_TOKEN_KEY,
          hashedAccessToken,
        );
      } else {
        localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
        localStorage.removeItem(STORAGE_HASHED_ACCESS_TOKEN_KEY);
      }
      if (refreshToken && hashedRefreshToken) {
        localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken);
        localStorage.setItem(
          STORAGE_HASHED_REFRESH_TOKEN_KEY,
          hashedRefreshToken,
        );
      } else {
        localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY);
        localStorage.removeItem(STORAGE_HASHED_REFRESH_TOKEN_KEY);
      }
      break;
    } catch (e) {
      // LocalStorage 存储限制 4M 应当及时清理
      localStorage.clear();
    }
  }
};
