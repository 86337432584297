import CollectionView from '@/components/views/collection';

const CollectionIndex = ({ match: { params } }: any) => {
  return (
    <CollectionView
      id={params.id.trim()}
      cardId={params.cardId?.replace?.(/%2F/g, ':')}
    />
  );
};

export default CollectionIndex;
