import React from 'react';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import Box from '@mui/material/Box';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { SxProps } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypePrism from 'rehype-prism-plus';
import rehypeHighlight from 'rehype-highlight';
import { OSS_RESOURCE_BASE } from '@/utils/config';

import './markdown-style.css';
import './hljs-atom-one-dark.min.css';
import './prism-one-dark.css';

export interface IMarkdownViewArguments {
  text?: string;
  style?: SxProps;
  allowHTML?: boolean;
  usePrism?: boolean;
}

export default React.memo<IMarkdownViewArguments>(
  ({ text = '', style = {}, allowHTML = false, usePrism = false }) => {
    const rehypePlugins = React.useMemo(() => {
      const plugins: any[] = [];
      if (usePrism) {
        plugins.push(rehypePrism);
      } else {
        plugins.push(rehypeHighlight);
      }
      if (allowHTML) {
        plugins.push(rehypeRaw);
      }
      plugins.push(rehypeKatex);
      return plugins;
    }, [allowHTML, usePrism]);
    return (
      <Box className="markdown-body" sx={{ ...style }}>
        <link
          rel="stylesheet"
          href={`${OSS_RESOURCE_BASE}/slides/assets/katex.css`}
          type="text/css"
          media="all"
        />
        <ReactMarkdown
          remarkPlugins={[remarkMath, remarkGfm]}
          rehypePlugins={rehypePlugins}
        >
          {text}
        </ReactMarkdown>
      </Box>
    );
  },
);
