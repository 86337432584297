import { Partner } from '@daimaxiagu/redux-partner';
import { emitter, Emitter } from '@daimaxiagu/emitter';
import { IAudio } from '@daimaxiagu/micro-frontend-provider';

import { RevealJSMethods } from './EmbbededRevealJS';
import { MEDIA_RESOURCE_BASE } from '@/utils/config';
import { getJsonPathFromId } from '@/utils/api/gameCard';

interface SlideCardPartnerEvents {
  CardReady: void;
  RevealJSReady: void;
  ChangeToNextCard: void;
  ChangeToLastCard: void;
}

export class SlideCardPartner extends Partner<never, SlideCardPartnerEvents> {
  public slideMethods?: RevealJSMethods;

  public readonly slideEvents: Emitter<Record<string, any>> = emitter();

  private slideId_: string = '';

  constructor({ play }: IAudio) {
    super('card.slide');
    this.slideEvents.on('slideHeadOverflow', () =>
      this.emit('ChangeToLastCard', undefined),
    );
    this.slideEvents.on('slideTailOverflow', () =>
      this.emit('ChangeToNextCard', undefined),
    );
    this.slideEvents.on('ready', ({ state: { indexh } }) => {
      play(
        `${MEDIA_RESOURCE_BASE}/slides-audio/${getJsonPathFromId(
          this.slideId_,
        )}/${(indexh as number) + 1}.mp3`,
      );
    });
    this.slideEvents.on('slidechanged', ({ state: { indexh } }) => {
      play(
        `${MEDIA_RESOURCE_BASE}/slides-audio/${getJsonPathFromId(
          this.slideId_,
        )}/${(indexh as number) + 1}.mp3`,
      );
    });
  }

  bindSlide(slideId: string) {
    this.slideId_ = slideId;
  }

  changeToLastCard() {
    this.emit('ChangeToLastCard', undefined);
  }

  changeToNextCard() {
    this.emit('ChangeToNextCard', undefined);
  }

  cardReady() {
    this.emit('CardReady', undefined);
  }

  revealJsReady() {
    this.emit('RevealJSReady', undefined);
  }
}
