export const workerPrefix = (
  emulator: string,
  pyodide = true,
  devMode = false,
) => `
importScripts('${emulator}');
var __DAIMAXIAGUA__DEVMODE__ = ${devMode};
var __DAIMAXIAGUA__PYODIDE__ = ${pyodide ? 'true' : 'false'};
var playerId;
var maxCommand = 256;
var commandCount = 0;
globalThis.setMaxCommand = function (max) {
  maxCommand = max;
};
globalThis.stdinLines = [];
globalThis.playerStdout = [];
globalThis.clearStdin = function clearStdin() {
  if (__DAIMAXIAGUA__PYODIDE__) {
    globalThis.stdinLines.length = 0;
  } else {
    // lines.length = 0;
  }
};
globalThis.pushStdin = function pushStdin(content) {
  if (__DAIMAXIAGUA__DEVMODE__) console.log('Stdin:', content);
  if (__DAIMAXIAGUA__PYODIDE__) {
    globalThis.stdinLines.push.apply(globalThis.stdinLines, content.split('\\n'));
  } else {
    var buffer = [];
    var length = content.length;
    for (var i = 0; i < length; i++) {
      var code = content[i].charCodeAt(0);
      if (code < 0x80) {
        buffer.push(code);
      } else if (code < 0x800) {
        buffer.push(0xc0 + (code >> 6));
        buffer.push(0x80 + (code & 0x3f));
      } else {
        buffer.push(0xe0 + (code >> 12));
        buffer.push(0x80 + ((code >> 6) & 0x3f));
        buffer.push(0x80 + (code & 0x3f));
      }
    }
    lines.push(new Uint8Array(buffer));
  }
};
globalThis.getStdout = function getStdout() {
  if (globalThis.playerStdout[globalThis.playerStdout.length - 1] === '') {
    globalThis.playerStdout.length--;
  }
  var content = globalThis.playerStdout.join('\\n');
  globalThis.playerStdout.length = 0;
  if (__DAIMAXIAGUA__DEVMODE__) console.log('Stdout:', content);
  return content;
};
globalThis.stdout = function _stdout(x) {
  globalThis.playerStdout.push(x);
};
globalThis.transferType = function transferType(type) {
  return {
    player: 'Player',
    exit: 'Exit',
    transistor: 'Transistor',
    jtg: 'Transistor',
    portal: 'Portal',
    brokenrobot: 'BrokenRobot',
    jqr: 'BrokenRobot',
    terminal: 'Terminal',
    passworddoor: 'PasswordDoor',
    'switch': 'Switch',
    laser: 'Laser',
  }[type.toLowerCase()] || type;
};
globalThis.loadMap = function loadMap(map, _playerId) {
  playerId = _playerId;
  commandCount = 0;
  globalThis.stdinLines.length = 0;
  globalThis.playerStdout.length = 0;
  var parse2dv2 = globalThis.indexMin.parse2dv2;
  var SyncEmulator = globalThis.indexMin.SyncEmulator;
  var eventToPlain = globalThis.indexMin.eventToPlain;
  globalThis.emulator = new SyncEmulator(parse2dv2(map));
  var events = globalThis.emulator.joinGame(_playerId);
  for (var i = 0; i < events.length; i++) {
    events[i][2] = eventToPlain(events[i][1], events[i][2]);
  }
  return events;
};
globalThis.runCommand = function runCommand(command) {
  if (commandCount++ > maxCommand) throw new Error('指令执行次数超限');
  if (globalThis.emulator.terminated) throw new Error('模拟已结束, 无法迭代');
  if (command !== 'OpenPasswordDoor') {
    globalThis.playerStdout.length = 0;
  }
  globalThis.clearStdin();
  var argv = Array(arguments.length + 1);
  argv[0] = command; argv[1] = playerId;
  for (var i = 1, j = 2; i < arguments.length; i++) argv[j++] = arguments[i];
  var eventToPlain = globalThis.indexMin.eventToPlain;
  var p = globalThis.emulator.execute.apply(globalThis.emulator, argv);
  for (var i = 0; i < p.events.length; i++) {
    p.events[i][2] = eventToPlain(p.events[i][1], p.events[i][2]);
  }
  if (p.events.length > 0) {
    if (globalThis.post_function) globalThis.post_function('emulatorEvents', p.events, globalThis.emulator.terminated);
    else globalThis.postFunction('emulatorEvents', p.events, globalThis.emulator.terminated);
  }
  if (!p.commandRunned) throw new Error('指令 ' + command + ' 无效');
  return p.result;
};
`;

export const pythonCodePrefix = `
def move_forward():
    import js
    return js.runCommand('MoveForward')

def move_backward():
    import js
    return js.runCommand('MoveBackward')

def turn_left():
    import js
    return js.runCommand('TurnLeft')

def turn_right():
    import js
    return js.runCommand('TurnRight')

def collect():
    import js
    return js.runCommand('Collect')

def attack():
    import js
    return js.runCommand('Attack')

def toggle(type: str):
    import js
    return js.runCommand('ToggleSwitch')

def is_exist_ahead(type: str):
    import js
    return js.runCommand('ExistAhead', js.transferType(type))

def is_on(type: str):
    import js
    return js.runCommand('IsOn', js.transferType(type))

def is_on_open_switch():
    import js
    return js.runCommand('IsOnOpenedSwitch')

def is_on_closed_switch():
    import js
    return js.runCommand('IsOnClosedSwitch')

def unlock_screen():
    import js
    result = js.runCommand('UnlockTerminal')
    if isinstance(result, str):
        js.clearStdin()
        js.pushStdin(result)
        return True
    else:
        return False

def hack(type: str):
    import js
    print('')
    js.runCommand("OpenPasswordDoor", js.getStdout())
`;
