import { intersection, cloneDeep } from 'lodash';
import { validate, Schema } from 'jsonschema';
import {
  ILevelCardInfo,
  CodeLanguageType,
  ICollectionGameLevelCard,
} from '@/types/data.d';
import { DefaultCode, COCOS_INDEX } from '@/utils/config';

export const supportedLanguages = ['cpp', 'python', 'scratch', 'go'];

const LevelJsonSchema: Schema = {
  required: true,
  type: 'object',
  properties: {
    language: {
      required: true,
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'string',
        enum: supportedLanguages,
      },
    },
    defaultLanguage: {
      type: 'string',
      enum: supportedLanguages,
    },
    description: {
      type: 'object',
      additionalProperties: {
        type: ['string', 'null'],
        required: true,
      },
    },
    template: {
      type: 'string',
      enum: ['movement_2'],
      required: true,
    },
    game: {
      type: 'string',
      enum: Object.keys(COCOS_INDEX),
      required: true,
    },
    title: {
      type: 'string',
      required: true,
    },
    code: {
      type: 'object',
      additionalProperties: {
        type: ['string', 'null'],
        required: true,
      },
    },
    map: {
      type: ['object', 'array'],
      required: true,
    },
    tips: {
      type: 'object',
      additionalProperties: {
        type: 'array',
        items: {
          type: 'string',
        },
        required: true,
      },
    },
    snippets: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};

interface ILevelInfoJson {
  language: string[];
  description?: Record<string, string> | null;
  template: string;
  code?: Record<string, string> | null;
  showSnippetBar?: boolean;
  game: string;
  title: string;
  map: any;
  tips?: Record<string, string[]> | null;
  snippets?: string[];
}

export const parseLevel = (levelInfoJsonLike: unknown): ILevelCardInfo => {
  validate(levelInfoJsonLike, LevelJsonSchema, { throwFirst: true });
  const levelInfoJson = levelInfoJsonLike as ILevelInfoJson;

  // 合法语言
  const languageList = intersection(
    levelInfoJson.language,
    supportedLanguages,
  ) as CodeLanguageType[];
  if (languageList.length === 0) {
    throw new Error('关卡支持的语言都不存在');
  }

  // 语言相关内容
  const defaultCode: Record<string, string> = {};
  const description: Record<string, string> = {};
  const tips: Record<string, string[]> = {};
  for (let i = 0, len = languageList.length; i < len; i++) {
    const language = languageList[i];
    defaultCode[language] =
      levelInfoJson.code?.[language] ?? DefaultCode[language] ?? '';
    description[language] =
      levelInfoJson.description?.[language] ??
      levelInfoJson.description?.['*'] ??
      '';
    tips[language] =
      levelInfoJson.tips?.[language] ?? levelInfoJson.tips?.['*'] ?? [];
  }

  return {
    supportedLanguages: languageList,
    allowedSnippets: levelInfoJson.snippets,
    cocosHTMLUri: COCOS_INDEX[levelInfoJson.game],
    codeRunnerTemplate: levelInfoJson.template,
    showSnippetBar: levelInfoJson.showSnippetBar !== false,
    defaultCode,
    description,
    tips,
    title: levelInfoJson.title,
    mapData: levelInfoJson.map,
  };
};

export const mergeLevelData = (
  originLevelData: ILevelCardInfo,
  levelItem: ICollectionGameLevelCard,
): ILevelCardInfo => {
  const levelData: ILevelCardInfo = cloneDeep(originLevelData);
  levelData.supportedLanguages = intersection(
    levelData.supportedLanguages,
    levelItem.language,
  );
  levelData.allowedSnippets =
    levelItem.allowedSnippets ?? levelData.allowedSnippets;
  levelData.defaultLanguage =
    levelItem.defaultLanguage ?? levelData.defaultLanguage;
  levelData.title = levelItem.title ?? levelData.title;
  return levelData;
};
