import { intersection, cloneDeep } from 'lodash';
import { validate, Schema } from 'jsonschema';
import { supportedLanguages } from './level';
import {
  ICollection,
  CollectionItemType,
  ICollectionGameLevelCard,
  ICollectionOJCard,
  ICollectionSlideCard,
  ICollectionPaperCard,
  ICollectionQuestionsCard,
  ICollectionVideoCard,
  ICollectionEmptyCard,
} from '@/types/data.d';

const CollectionJsonSchema: Schema = {
  type: 'object',
  required: true,
  properties: {
    title: {
      type: 'string',
      required: true,
    },
    preface: {
      type: 'string',
    },
    hide: {
      type: 'boolean',
    },
    toc: {
      type: 'array',
      required: true,
      items: {
        type: 'object',
        anyOf: [
          // Empty
          {
            type: 'object',
            properties: {
              level: {
                type: 'number',
                required: true,
              },
              title: {
                type: 'string',
                required: true,
              },
              permission: {
                type: 'string',
                required: false,
              },
            },
          },
          // OJ / Slide / Paper
          {
            type: 'object',
            properties: {
              level: {
                type: 'number',
                required: true,
              },
              title: {
                type: 'string',
                required: true,
              },
              id: {
                type: 'string',
                required: true,
              },
              permission: {
                type: 'string',
                required: false,
              },
            },
          },
          // Level
          {
            type: 'object',
            properties: {
              level: {
                type: 'number',
                required: true,
              },
              title: {
                type: 'string',
                required: true,
              },
              id: {
                type: 'string',
                required: true,
              },
              language: {
                type: 'array',
                items: {
                  type: 'string',
                  enum: supportedLanguages,
                },
                uniqueItems: true,
              },
              defaultLanguage: {
                type: 'string',
                enum: supportedLanguages,
              },
              snippets: {
                type: 'array',
                items: {
                  type: 'string',
                },
                uniqueItems: true,
              },
              permission: {
                type: 'string',
                required: false,
              },
            },
          },
          // Questions
          {
            type: 'object',
            properties: {
              level: {
                type: 'number',
                required: true,
              },
              title: {
                type: 'string',
                required: true,
              },
              id: {
                type: 'string',
                required: true,
              },
              questions: {
                type: 'array',
                required: true,
                items: {
                  type: ['string', 'array'],
                  minItems: 2,
                  maxItems: 2,
                  items: {
                    type: ['string', 'number', 'array'],
                    items: {
                      type: 'number',
                    },
                  },
                },
              },
              permission: {
                type: 'string',
                required: false,
              },
            },
          },
        ],
      },
    },
  },
};

interface ICollectionCardJson {
  title: string;
  level: number;
  id?: string;
  url?: string;
  language?: string[] | null;
  defaultLanguage?: string;
  snippets?: string[];
  questions?: (string | [string, number | number[]])[];
  permission?: string;
}

interface ICollectionJson {
  title: string;
  preface?: string;
  hide?: boolean;
  toc: ICollectionCardJson[];
}

export const parseCollection = (
  collectionInfoJsonLike: unknown,
): ICollection => {
  validate(collectionInfoJsonLike, CollectionJsonSchema, { throwFirst: true });
  const collectionJson = collectionInfoJsonLike as ICollectionJson;

  // 层级检查
  let previousLevel = 0;
  for (let i = 0, len = collectionJson.toc.length; i < len; i++) {
    if (collectionJson.toc[i].level - previousLevel > 1) {
      throw new Error('Invalid Collection TOC Level.');
    }
    previousLevel = collectionJson.toc[i].level;
  }

  let dirId = 1;
  return {
    title: collectionJson.title,
    toc: collectionJson.toc.map(card => {
      const rawId = card.id ?? ':';
      const [cardType, cardId] = rawId.split(':');
      switch (cardType.toLowerCase()) {
        case 'level': {
          return {
            title: card.title,
            type: CollectionItemType.Level,
            level: card.level,
            id: cardId,
            rawId,
            language: card.language
              ? intersection(card.language, supportedLanguages)
              : cloneDeep(supportedLanguages),
            defaultLanguage:
              card.defaultLanguage &&
              supportedLanguages.includes(card.defaultLanguage)
                ? card.defaultLanguage
                : undefined,
            allowedSnippets: card.snippets,
            permission: card.permission,
          } as ICollectionGameLevelCard;
        }
        case 'oj': {
          return {
            rawId,
            title: card.title,
            level: card.level,
            id: cardId,
            type: CollectionItemType.OJ,
            permission: card.permission,
          } as ICollectionOJCard;
        }
        case 'slide': {
          return {
            rawId,
            title: card.title,
            level: card.level,
            id: cardId,
            type: CollectionItemType.Slide,
            permission: card.permission,
          } as ICollectionSlideCard;
        }
        case 'paper': {
          return {
            rawId,
            title: card.title,
            level: card.level,
            id: cardId,
            type: CollectionItemType.Paper,
            permission: card.permission,
          } as ICollectionPaperCard;
        }
        case 'questions': {
          const scoreMap: Record<string, number[]> = {};
          for (let i = 0, len = card.questions?.length ?? 0; i < len; i++) {
            const question = card.questions![i];
            if (typeof question === 'string') {
              scoreMap[question] = [];
            } else if (typeof question[1] === 'number') {
              scoreMap[question[0]] = [question[1]];
            } else {
              scoreMap[question[0]] = question[1];
            }
          }
          return {
            type: CollectionItemType.Questions,
            title: card.title,
            level: card.level,
            rawId,
            id: cardId,
            questions:
              card.questions?.map?.(question =>
                typeof question === 'string' ? question : question[0],
              ) ?? [],
            scores: scoreMap,
            permission: card.permission,
          } as ICollectionQuestionsCard;
        }
        case 'video': {
          return {
            type: CollectionItemType.Video,
            title: card.title,
            level: card.level,
            rawId,
            id: cardId,
            url: card.url,
            permission: card.permission,
          } as ICollectionVideoCard;
        }
        default: {
          const id = `${dirId++}`;
          // Empty
          return {
            type: CollectionItemType.Empty,
            rawId: `dir:${id}`,
            id,
            title: card.title,
            level: card.level,
            permission: card.permission,
          } as ICollectionEmptyCard;
        }
      }
    }),
  };
};
