import { api, HOST } from '@daimaxiagu/micro-frontend-provider';
import { Model, Enforcer, newEnforcer, Util } from 'casbin';

import { rawAuth, STORAGE_CASBIN_CACHE } from './storage';

interface BaseResponse {
  data: string;
  lastupdate: number;
}

const getSubjectPolicyApi = api<
  { subject: string; token: string; timestamp: number },
  BaseResponse
>(
  ({ subject, token, timestamp }) => ({
    baseURL: `https://${HOST}/api/frontend-permission-sync/`,
    url: '/',
    params: {
      subject,
      timestamp,
    },
    headers: {
      Authorization: [`Bearer ${token}`],
    },
  }),
  ({ data }) => data,
  undefined,
  false,
);

export class Authorizer {
  public cookieKey?: string;

  public cacheExpiredTime: number;

  public user?: string;

  public enforcer?: Enforcer;

  public permissionCache: Map<string, boolean> = new Map();

  constructor(cacheExpiredTime = 60) {
    this.cacheExpiredTime = cacheExpiredTime;
    const deleteKeys: string[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(0);
      if (key?.startsWith('casbinjs_')) {
        deleteKeys.push(key);
      }
    }
    deleteKeys.forEach(k => localStorage.removeItem(k));
  }

  public async initEnforcer(s: string) {
    const obj = JSON.parse(s);
    if (!('m' in obj)) {
      throw Error('No model when init enforcer.');
    }
    const m = new Model();
    m.loadModelFromText(obj.m);
    const enforcer = await newEnforcer(m);
    if ('p' in obj) {
      for (const sArray of obj.p) {
        const [pType, ...arr] = (sArray as string[]).map(v => v.trim());
        if (pType === 'p') {
          await enforcer.addPolicy(...arr);
        } else if (pType === 'g') {
          await enforcer.addGroupingPolicy(...arr);
        }
      }
    } else if ('c' in obj) {
      for (const [object, action] of obj.c) {
        await enforcer.addPolicy(this.user!, object, action, 'allow');
      }
    }
    await enforcer.addFunction('globMatch', Util.globMatch);
    await enforcer.addNamedMatchingFunc('g', Util.globMatch);
    return enforcer;
  }

  public async convertPolicy(data: string) {
    return JSON.stringify({
      ...JSON.parse(data),
      p: undefined,
      c: (
        await (
          await this.initEnforcer(data)
        ).getImplicitResourcesForUser(this.user!)
      )
        .filter(k => k[3] === 'allow')
        .map(([, object, action]) => [object, action] as [string, string]),
    });
  }

  /**
   * Set the user subject for the authroizer
   * @param user The current user
   */
  public async setUser(user: string) {
    if (user === this.user) {
      return;
    }
    this.permissionCache.clear();
    this.user = user;
    let config: any = {};
    try {
      config = JSON.parse(
        localStorage.getItem(STORAGE_CASBIN_CACHE(this.user)) ?? '{}',
      );
    } catch {}

    if (typeof config.expired !== 'number') {
      // 啥都没有，获取数据
      const { data, lastupdate } = await getSubjectPolicyApi({
        subject: this.user || '',
        token: rawAuth.accessToken || '',
        timestamp: 0,
      });
      config = {
        data: await this.convertPolicy(data),
        expired: Date.now() + 1000 * this.cacheExpiredTime,
        lastupdate,
      };
    } else if (config.expired < Date.now()) {
      // 过期，尝试获取更新
      const { data, lastupdate } = await getSubjectPolicyApi({
        subject: this.user || '',
        token: rawAuth.accessToken || '',
        timestamp: config.lastupdate,
      });
      config.expired = Date.now() + 1000 * this.cacheExpiredTime;
      config.lastupdate = lastupdate;
      if (data !== '{}') {
        config.data = await this.convertPolicy(data);
      }
    }
    localStorage.setItem(
      STORAGE_CASBIN_CACHE(this.user),
      JSON.stringify(config),
    );
    this.enforcer = await this.initEnforcer(config.data);
  }

  public async can(action: string, object: string, domain?: string) {
    const p = domain ? `${action} ${domain} ${object}` : `${action} ${object}`;
    const result = this.permissionCache.get(p);
    if (result === undefined) {
      if (this.enforcer === undefined) {
        throw Error('Enforcer not initialized');
      } else if (domain === undefined) {
        const result = await this.enforcer.enforce(this.user, object, action);
        this.permissionCache.set(p, result);
        return result;
      } else {
        const result = await this.enforcer.enforce(
          this.user,
          domain,
          object,
          action,
        );
        this.permissionCache.set(p, result);
        return result;
      }
    } else {
      return result;
    }
  }

  public async cannot(action: string, object: string, domain?: string) {
    return !(await this.can(action, object, domain));
  }

  public async canAll(action: string, objects: string[], domain?: string) {
    for (const object of objects) {
      if (await this.cannot(action, object, domain)) {
        return false;
      }
    }
    return true;
  }

  public async canAny(action: string, objects: string[], domain?: string) {
    for (const object of objects) {
      if (await this.can(action, object, domain)) {
        return true;
      }
    }
    return false;
  }
}
