import React from 'react';
import { Helmet } from '@modern-js/runtime/head';
import { useParams } from '@modern-js/runtime/router';
// import { UserGateKeeper } from '@daimaxiagu/micro-frontend-provider';
import CollectionView from '@/components/views/collection';

const LevelIndex = React.memo(() => {
  const { cocosUri } = useParams<{ cocosUri?: string }>();
  return (
    <>
      <Helmet>
        <title>调试模式</title>
      </Helmet>
      {/* <UserGateKeeper object="daimaxiagu:cocos-debug-page" action="visit">
        <CollectionView
          id="test-basic"
          debugCocosUri={decodeURIComponent(cocosUri?.trim() ?? '')}
        />
      </UserGateKeeper> */}
      <CollectionView
        id="test-basic"
        debugCocosUri={decodeURIComponent(cocosUri?.trim() ?? '')}
      />
    </>
  );
});

export default LevelIndex;
